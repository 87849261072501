import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import Cookies from "universal-cookie";
import { DisabledByDefault } from "@mui/icons-material";
// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);
const cookie = new Cookies();
const casos = "/InfoCasos/";

const navConfig = [
  {
    title: "Certificados",
    icon: <AiIcons.AiFillCaretDown />,
    subMenu: [
      {
        title: "Consulta Certificados",
        path: "InfoCertificados/" + cookie.get("correo"),
        icon: <AiIcons.AiOutlineFieldTime />,
      },
      {
        title: "Crear Certificado",
        path: "CrearCertificado",
        icon: <AiIcons.AiFillFileAdd />,
      },
      {
        title: "Contreebute",
        path: "InfoContreebute",
        icon: <AiIcons.AiFillCompass />,
      },
      {
        title: "InfoTesoreria",
        path: "InfoTesoreria",
        icon: <AiIcons.AiFillAlert />,
      },
    ],
  },
  {
    title: "Plantillas",
    icon: <AiIcons.AiFillCaretDown />,
    subMenu: [
      {
        title: "Carga Plantilla",
        path: "InfoCargaPlantilla",
        icon: <AiIcons.AiOutlineCloudUpload />,
      },
    ],
  },

  {
    title: "Modulos",
    icon: <AiIcons.AiFillCaretDown />,
    subMenu: [
      {
        title: "Modulos",
        path: "InfoModulo",
        icon: <AiIcons.AiFillFolder />,
      },
      {
        title: "Configuracion de correos",
        path: "PlantillasCorreo",
        icon: <AiIcons.AiOutlineMail />,
      },
    ],
  },
  {
    title: "Administración",
    icon: <AiIcons.AiFillCaretDown />,
    subMenu: [
      {
        title: "Gestión de Usuarios",
        path: "InfoUsuarios",
        icon: <AiIcons.AiOutlineUser />,
      },
      {
        title: "Plantillas",
        path: "InfoPlantillas",
        icon: <AiIcons.AiOutlineException />,
      },
      {
        title: "Coordenadas",
        path: "InfoCoordenadas",
        icon: <AiIcons.AiFillCompass />,
      },
      {
        title: "Crear Campos",
        path: "CreaCampos",
        icon: <AiIcons.AiFillTablet />,
      },
      {
        title: "Correos Copia",
        path: "InfoCopias",
        icon: <AiIcons.AiTwotoneAlert />,
      },
      {
        title: "Reporte",
        path: "Reporte",
        icon: <AiIcons.AiOutlinePaperClip />,
      },
      {
        title: "Reporte x Fechas",
        path: "ReporteFecha",
        icon: <AiIcons.AiOutlineCalendar />,
      },
      {
        title: "Logs",
        path: "InfoLogs",
        icon: <AiIcons.AiTwotoneAlert />,
      },
    ],
  },
];

export default navConfig;
