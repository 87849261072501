import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";

import InfoPlantillas from "./pages/Obtener/InfoPlantillas";
import InfoUsuarios from "./pages/Obtener/InfoUsuarios";
import Formulario from "./pages/consultar";
import CrearCertificado from "./pages/CrearCertificado";
import ConsultarAutenticado from "./pages/Obtener/ConsultarAutenticado";
import DetalleCertificado from "./pages/Obtener/DetalleCertificado";
import InformePDF from "./pages/InformePDF";
import InfoContreebute from "./pages/Obtener/InfoContreebute";
import InfoModulo from "./pages/Obtener/InfoModulo";
import CrearModulo from "./pages/CreaModulo";
import CreaContreebute from "./pages/CreaContreebute";
import CrearParametros from "./pages/Parametros";
import InfoPlantillasCorreo from "./pages/Obtener/InfoPlantillasCorreo";
import CrearUsuario from "./pages/CreaUsuario";
import InfoLogs from "./pages/Obtener/InfoLogs";
import CertificadoInterno from "./pages/Obtener/CertificadoInterno";
import InfoCoordenadas from "./pages/Obtener/InfoCoordenadas";
import CrearCoordenada from "./pages/CreaCoordenada";
import CrearCampos from "./pages/CreaCampos";
import SubePlantilla from "./pages/SubePlantilla";
import InfoCargaPlantilla from "./pages/Obtener/InfoCargaPlantilla";
import Error from "./pages/Obtener/Error";
import InfoTesoreria from "./pages/Obtener/InfoTesoreria";
import Historial from "./pages/Obtener/HistorialCertificados";
import UnionAnexos from "./pages/Obtener/UnionAnexos";
import InfoCopias from "./pages/Obtener/InfoCopias";
import CreaCopias from "./pages/CreaCopias";
import HuellaNav from "./pages/HeaderHuella";
import InformePDFSinFondo from "./pages/InformePDFSinFondo";
import CargaTesoreria from "./pages/CargaTesoreria";
import Reporte from "./pages/Obtener/Reporte";
import ReporteCalendar from "./pages/Obtener/ReporteCalendar";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/Consultar" />, index: true },

        { path: "InfoCertificados/:correo", element: <ConsultarAutenticado /> }, //Consultar
        { path: "InfoPlantillas", element: <InfoPlantillas /> },
        { path: "InfoUsuarios", element: <InfoUsuarios /> },
        { path: "CrearCertificado", element: <CrearCertificado /> },
        { path: "DetalleCertificado", element: <DetalleCertificado /> },

        {
          path: "InfoLogs",
          element: <InfoLogs />,
        },

        { path: "PlantillasCorreo", element: <InfoPlantillasCorreo /> },
        {
          path: "InfoContreebute",
          element: <InfoContreebute />,
        },
        {
          path: "InfoTesoreria",
          element: <InfoTesoreria />,
        },

        {
          path: "CrearModulo",
          element: <CrearModulo />,
        },

        {
          path: "CrearPrametros",
          element: <CrearParametros />,
        },
        {
          path: "Historial/:id",
          element: <Historial />,
        },

        {
          path: "CrearContreebute",
          element: <CreaContreebute />,
        },
        {
          path: "InfoModulo",
          element: <InfoModulo />,
        },

        {
          path: "InfoCoordenadas",
          element: <InfoCoordenadas />,
        },
        {
          path: "CreaUsuario",
          element: <CrearUsuario />,
        },
        {
          path: "CreaCoordenada",
          element: <CrearCoordenada />,
        },
        {
          path: "CreaCampos",
          element: <CrearCampos />,
        },
        {
          path: "SubePlantilla",
          element: <SubePlantilla />,
        },
        {
          path: "InfoCopias",
          element: <InfoCopias />,
        },
        {
          path: "CreaCopias",
          element: <CreaCopias />,
        },
        {
          path: "InfoCargaPlantilla",
          element: <InfoCargaPlantilla />,
        },
        {
          path: "CargaTesoreria",
          element: <CargaTesoreria />,
        },
        {
          path: "HeaderHuella",
          element: <HuellaNav />,
        },
        {
          path: "Reporte",
          element: <Reporte />,
        },
        {
          path: "ReporteFecha",
          element: <ReporteCalendar />,
        },
        InfoCargaPlantilla,
      ],
    },

    {
      path: "Consultar",
      element: <Formulario />,
    },
    {
      path: "CertificadoInterno/:id",
      element: <CertificadoInterno />,
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "Informe/:id",
      element: <InformePDF />,
    },
    {
      path: "InformeSinFondo/:id",
      element: <InformePDFSinFondo />,
    },

    {
      path: "Anexos/:certificado",
      element: <UnionAnexos />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      path: "Error",
      element: <Error />,
    },
  ]);

  return routes;
}
